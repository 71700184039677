import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Tout projet devrait être suivi simultanément sur 3 dimensions:`}</p>
    <ul>
      <li parentName="ul">{`le temps `}</li>
      <li parentName="ul">{`le coût `}</li>
      <li parentName="ul">{`l'avancement`}</li>
    </ul>
    <p>{`Arrêtons nous sur la troisième, et examinons les indicateurs visuels pouvant être utiles.`}</p>
    <h3>{`Morceler et Compter`}</h3>
    <p>{`Le plus robuste consiste à `}<strong parentName="p">{`morceler`}</strong>{` l'objectif global d'un projet en éléments concrets observables (le nombre de parpaings posés sur le mur) indépendamment des tâches, du cout, des efforts et du temps passé.`}</p>
    <p>{`Il est alors possible de construire des indicateurs très efficaces qui vont simplement `}<strong parentName="p">{`compter`}</strong>{`.`}</p>
    <p>{`Quand le projet consiste à appliquer une succession d'étapes à des objets que l'on peut énumérer (stories Scrum, todo list, ..) il suffit de compter le nombre d'objets présents dans chaque état de son cycle de vie. Cycle qui peut être plus ou moins détaillé:`}</p>
    <ul>
      <li parentName="ul">{`binaire   -> A faire, Fini`}</li>
      <li parentName="ul">{`classique -> A faire, En cours, Fini`}</li>
      <li parentName="ul">{`complexe  -> Créé, Accepté, Estimé, Planifié, En cours, Fini, Livré`}</li>
    </ul>
    <p>{`Et de représenter ces comptages visuellement: `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "505px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "23.926380368098158%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVQY0x2QPU/CUBhG++ucXU10cFAXZidcXCQkuDExsBGMbiaaoOJHgrUVB2oCFKFQ5auixbYUCjUebzu8y33ufe9zjjToDxiPxniehz+bMRyOaOk6uhjDMPB9nyAIsCxLZEOmU5vux4jSY50bpcmd2sB1XfHWIwxDpGdVRVUUbNvGE0Hp6pp0Ok0qlSKXy4kFU+zvbzStFt8z3lpcVTTW9wtsHJyxlSxQq3d4Ul5wvRmS2euiN5o0RSPX9ZBlOV4UTTabJZM55lXTeH836XZ7tPU6J+f3bB6es5O6ZDddYi9TZjuZZ2RNkKJfpz8Oy2WAI9qUy+W4XbFYxHEcgfoZ41arVTodA/5+ubitspbIkzg6pdXuYfYt7h4qMbpkWWP8+Tz2FCGbpokqNEQOV6sVi8WCucgnk68YPxRnPeHwstJArrVZBnN+wxU/wm3k8B+yEllDYLhOPgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Avancement",
          "title": "Avancement",
          "src": "/static/2b7e8dfa34b44cbef98840e8c5e04663/c0cb9/Avancement.png",
          "srcSet": ["/static/2b7e8dfa34b44cbef98840e8c5e04663/222b7/Avancement.png 163w", "/static/2b7e8dfa34b44cbef98840e8c5e04663/ff46a/Avancement.png 325w", "/static/2b7e8dfa34b44cbef98840e8c5e04663/c0cb9/Avancement.png 505w"],
          "sizes": "(max-width: 505px) 100vw, 505px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Même principe s'il s'agit de propriétés que l'on peut énumérer, par exemple sur un projet de rédaction d'un document de spécification: `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.16564417177914%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsSAAALEgHS3X78AAAB80lEQVQoz1WTV5LiQBBEuf85OAB8QPABQeAHJ8HivUcWI1YIL+0btRZmKoKOanVlZZYh5Hne9Xo9Ho+n08lxnMPh8Pe/8YWn2/XqvV6uZT0N3bXts+Pc73fPtxA/4iaTyXg81nV9tVpNp1NxHQ6HhmHsdrv9fG4sl6aiXGzbsqzb7RaAH4+HpmmQnM/n/X4Ps23br9dLPLuuy6lqmtxsSnIDWq7EBODL5ZJOp+PxeKFQyOfz0Wg0EolUKpXtdrtcLheLBVp0Ve10Oq1Wiy/Iqdfr0EAQErnfJET/8a3dbjcajXK5LMtyJpOJxWLhcDiZTJK63+/To6BmYXffADebTZ4BwylOaMkCrFgsgqcpjq//G0zZz+fTNE2aRDSnoig4dEtVVVpARq65XA5FlEY7KTYA441Go/V63ev1KAlHZCEIztlsxit1ZrPZbrcLOcwf8M+aUTEYDKgW5fP5nFyE0ipkJxIJSZLoLkmR8wG7vuEwVbRRNiT0VpyoAAyMa6lUAsxqfMBURVbeUEiHEAmzGA9PKAeAX6vVUqkUMezFL9lvYyWBUTb8xG02G7aKwZqGgVquLBUbFWwYHpnEVKvVKgwsCQ78iKRsMAz2YFk4rBe5qO4bzJDE34DczIasPDO2t8N3MLbjWKt1X5LrcHx9MRc27B+iHO+gBkyb6QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "syst",
          "title": "syst",
          "src": "/static/84abd7ebc86ff4af5fa1e248f54d7712/a6d36/syst.png",
          "srcSet": ["/static/84abd7ebc86ff4af5fa1e248f54d7712/222b7/syst.png 163w", "/static/84abd7ebc86ff4af5fa1e248f54d7712/ff46a/syst.png 325w", "/static/84abd7ebc86ff4af5fa1e248f54d7712/a6d36/syst.png 650w", "/static/84abd7ebc86ff4af5fa1e248f54d7712/91e7e/syst.png 692w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Sont ajoutés ici: `}</p>
    <ul>
      <li parentName="ul">{`l'évolution du périmètre en cours de projet qui évidemment change la valeur de l'avancement`}</li>
      <li parentName="ul">{`l'historique de la progression de l'avancement qui apporte une information dynamique: est ce que ça avance ? depuis combien de temps est ce bloqué ? Et prévient même si on revient en arrière.`}</li>
    </ul>
    <h3>{`Burndown Chart`}</h3>
    <p>{`Le burndown chart très utilisé par les méthodes agiles est un outil `}<strong parentName="p">{`très pertinent`}</strong>{` pour prédire la date de terminaison d'une tâche ou du projet. Ou pour estimer le contenu qui sera disponible à une date future. Il suffit de prolonger visuellement le rythme actuel pour évaluer la date où les courbes se croisent.`}</p>
    <p>{`La version la plus complète est le Burnup Chart (la version ascendante) avec la visualisation des évolutions de périmètre.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "619px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.34969325153374%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAABIklEQVQoz52S63KDIBCF8/5P2XSUCF647YJczRZtmmZsfvSbgUHhuHjOXmqtpZTtD+oZj90LPaSUtn/xTqy1HkcxNeZ5pplz7pz7JY4x+idom2ZE7LqulFpyySmvqw8hrI0fMQ36ZN/3xphlWaSURlultFbG4eqi1igk9MYJOknuUKXXys+3hTBimLS7KWTSsrqRQ4dJJKb6J/9cSg4RJDAFQ8oJvQS/7AKwqJTarX4Rb36FUvMacdQfPtgX23KJHbter58nlWkoy0mmgYe0O1mPhI/bVufBO38uNjjF/AigvgmWmsnPc+a8xnSItZ0ADRAI2HCNPZhHkPTeWGumKUtZm01fhgkuxgZlJoSg2IZhoK6glrg1GGO0pl06M5Pyu0Pviqa8FswRaykAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "burn",
          "title": "burn",
          "src": "/static/c78f6038e71c37e6ee8a9366d5d98ea0/e628c/burn.png",
          "srcSet": ["/static/c78f6038e71c37e6ee8a9366d5d98ea0/222b7/burn.png 163w", "/static/c78f6038e71c37e6ee8a9366d5d98ea0/ff46a/burn.png 325w", "/static/c78f6038e71c37e6ee8a9366d5d98ea0/e628c/burn.png 619w"],
          "sizes": "(max-width: 619px) 100vw, 619px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Groupe de projets`}</h3>
    <p>{`Passons au niveau du groupe de projets partageant le même processus, les mêmes activités. Par exemple: `}</p>
    <ul>
      <li parentName="ul">{`spécification, codage, tests unitaires, tests fonctionnel ... dans un domaine logiciel`}</li>
      <li parentName="ul">{`terrassement, maçonnerie, plomberie, électricité, peinture, ... dans le bâtiment`}</li>
    </ul>
    <p>{`Il est très facile de représenter l'avancement global à partir d'indicateurs d'avancement par activité et sans forcement avoir besoin d'en dire beaucoup sur l'ordonnancement de ces activités: séquentiel, parallèle ou plus complexe. Cette tolérance/flexibilité n'est pas éliminatoire car l'`}{`œ`}{`il humain ajoute automatiquement un contexte d'interprétation: tout le monde sait bien qu'il est souhaitable que le terrassement et la maçonnerie soient terminées avant que la peinture ne démarre. Ou qu'il ne sert à rien d'essayer de terminer le test fonctionnel alors que les spécifications ne sont pas achevées. Mais que l'électricité et la plomberie peuvent très bien avancer en parallèle...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "326px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "103.06748466257669%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsTAAALEwEAmpwYAAACE0lEQVQ4y4WTV28CQQyE8///CBLiiS6aqAod0QWI3nvvLR/4BEEJhx9We74dz3i8+3X9Ly6XC2u32x0MBo/Pv/GlAna5XPl8vt/vTyaT3yWOx6Ma+Hw+syYSiWq1Go/H2+32A3w4HMLh8Ol0+sAcCoUKhUIqlRqPx4/ker02Go1SXQ0ciUSSySQrYDICAGwwGD6Dc7lcvV4PBoPYVqlUptMpaufzuc1mk7bVegaGYbFYjM79fj+2BQIBRgD4c89ortVqgDHcbrezut3u2Wzm8XjUZIsqFG63W1YcXq1WsC0WC/ZiwVsww0Ae59b3oATg/X7PhhLL5fKFWSpBKAy73U4ABGzgWRuNBhZks1lGKMN7ATebTY1Gg8PgO50OSTDsObrZbJiZXq+3WCxWqxXzFTB6cFKoOM0nqgTGkFBBUaRCCDidTjPCJzOWOJ3OaDTKJGSqdCtPgkKsHKU0tkPr9XqxQzGM0fOPo8PhkCpIgIp/qBCTMQJmhLRarVKpVC6XR6MRv25gOvT5fN/34CbodDq6fTBTCPF0wSqFuO30T16RfbkHAJzkJfV6PZJcBk5zTq4kzPTMVdFqtfSIlhtYkNIerw9LAZNh43A45JIhjbeNKVxMs9nMnqTCTG04QebugbEki8UiDDwg2AAAM5lMwMiQf4LhAQOY2twtnCOZyWQ4CgB+AQunlFBkX98EcrbvQ67nD3N7i1agnwuYAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "groupprojets12",
          "title": "groupprojets12",
          "src": "/static/2160ae55c9c25c4edafa143e29e45d2f/ce9b1/groupprojets12.png",
          "srcSet": ["/static/2160ae55c9c25c4edafa143e29e45d2f/222b7/groupprojets12.png 163w", "/static/2160ae55c9c25c4edafa143e29e45d2f/ff46a/groupprojets12.png 325w", "/static/2160ae55c9c25c4edafa143e29e45d2f/ce9b1/groupprojets12.png 326w"],
          "sizes": "(max-width: 326px) 100vw, 326px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Il y a trois façons de lire ce diagramme:`}</p>
    <ul>
      <li parentName="ul">{`horizontal: l'avancement de chaque projet et le reste à faire par activité`}</li>
      <li parentName="ul">{`vertical: l'avancement de chaque activité et le reste à faire par projet`}</li>
      <li parentName="ul">{`à l'intersection: le reste à faire pour un projet x activité`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      